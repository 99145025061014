.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  border-radius: 50%;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-scrollbar {
  
  overflow-y: scroll;
  overflow-x: hidden;

  scrollbar-color: dark light;
  /* thumb and track color */
  scrollbar-width: auto;
  /* thin, auto, or none */
}

.tag-list {
  margin-top: 10px;
  margin-left: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
}

.fade-out {
  animation: fadeOut 2s forwards;
  /* Make sure the animation duration here matches the timeout duration in the triggerRipple function */
}

@keyframes fadeOut {
  to {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    /* add this if you want to collapse the space taken by the element */
  }
}

.tag {
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  background-color: rgb(245, 243, 243);
  border: 1px #000000 solid;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}


.text-tag {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
}

@keyframes slideAndFade {
  0% {
    transform: translate(-50%, -150%);
    /* Start above the screen */
    opacity: 1;
  }

  10% {
    transform: translate(-50%, -50%);
    /* Move into the center */
    opacity: 1;
  }

  90% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }
}

.sent-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 70px;
  color: rgb(51, 46, 46);
  padding: 30px;
  background-color: white;
  border-radius: 15px;
  z-index: 10;
  animation: slideAndFade 8s ease forwards;
  /* Use forwards to retain the final state */
}

.post-inner-card {
  width: 90%;
  max-height: 900px;
}
.flip-card {
  perspective: 1000px; /* Change as needed */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateX(180deg);
}

/* Front and Back Side Styling */
.flip-card-front, .flip-card-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateX(180deg);
}
