.custom-scrollbar::-webkit-scrollbar {
    width: 12px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .custom-scrollbar {
    scrollbar-color: dark light; /* thumb and track color */
    scrollbar-width: thin; /* thin, auto, or none */
  }